const callBtn = document.querySelectorAll('.js-call');
const bodyEl = document.querySelector('.js-body');
const modal = document.querySelector('.js-modal');
const congratEl = document.querySelector('.js-congratulate');
const closeEl = document.querySelector('.js-close');
const clickArea = document.querySelector('.js-click-area');
const container_call =  document.querySelector('.call');

if(td==undefined)
    {
       var td="" 
    }


let selector = document.querySelectorAll('input[type="tel"]');
let input = new Inputmask('+38 099-999-99-99');

function call()
{
    callBtn.forEach
    (
        el => el.addEventListener('click', function()
        {
            modal.classList.add('modal-active');
            bodyEl.classList.add('main-active');

            clickArea.addEventListener('click', function()
            {
                modal.classList.remove('modal-active');
                bodyEl.classList.remove('main-active');
            })

        })
    );
}

function congratulate()
{
    congratEl.classList.add('active');
    container_call.classList.add('hide')
    
    closeEl.addEventListener('click', function()
    {
        congratEl.classList.remove('active');
        modal.classList.remove('modal-active');
        bodyEl.classList.remove('main-active');
        container_call.classList.remove('hide');
    });
}

$(document).ready(function ()
{
    call();

    $('.owl-carousel').owlCarousel({
        items: 3,
        loop: true,
        slideTransition: 'linear',
        nav: true,
        dots: false
    });

    $('.popup-gallery').magnificPopup({
        delegate: '.owl-item a',
        type: 'image',
        tLoading: 'Loading image #%curr%...',
        // mainClass: 'mfp-img-mobile',
        gallery: {
            enabled: true,
            navigateByImgClick: true,
            preload: [0,1] // Will preload 0 - before current, and 1 after the current image
        },
        image: {
            tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
            titleSrc: function(item) {
                return item.el.attr('title');
            }
        }
    });

    input.mask(selector);

    let validateConsultation = function(selector, rules, succesModal, yaGoal) {
        new window.JustValidate(selector, {
            rules: rules,
            submitHandler: function(form) {
                let formData = new FormData(form);

                let xhr = new XMLHttpRequest();

                xhr.onreadystatechange = function() {
                    if (xhr.readyState === 4) {
                        if (xhr.status === 200) {
                            console.log('Отправлено');
                        }
                    }
                }

                xhr.open('POST', td+'/mail.php', true);
                xhr.send(formData);

                form.reset();

                congratulate();
            },

            messages: {
                name: {
                    required: 'Пожалуйста, введите ваше Имя...'
                },
                tel: {
                    required: 'Мы потеряли Ваш номер...'
                }
            }
        });
    }

    validateConsultation('.js-form-consultation',
        {
            name: {
                required: true
            },
            tel: {
                required: true
            }
        });
});

// console.log(er);